var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-factory-login" }, [
    _c("img", {
      staticClass: "title",
      attrs: { src: _vm.ossHost + "img/title.png", alt: "" }
    }),
    _c("div", { class: { "input-box": true, err: _vm.errText } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        attrs: { type: "text", maxlength: "10", placeholder: "请输入您的姓名" },
        domProps: { value: _vm.name },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
            _vm.change
          ]
        }
      }),
      _c("div", { staticClass: "err-msg" }, [_vm._v("仅支持输入中文")])
    ]),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        {
          class: { btn: true, pass: _vm.name && !_vm.errText },
          on: { click: _vm.submitClick }
        },
        [_vm._v("进入")]
      ),
      _c("img", { attrs: { src: _vm.ossHost + "img/logo.png", alt: "" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }